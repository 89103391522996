import React from 'react'
import classNames from 'classnames'
import {LinkToPageProps} from './interface'
import s from './link-to-page.scss'

export const LinkToPage: React.FC<LinkToPageProps> = ({
  dataHook,
  style,
  className,
  isExternalUrl,
  url,
  onClick,
  children,
  disabled,
}) => {
  if (disabled) {
    return <>{children}</>
  }

  return (
    <a
      data-hook={dataHook}
      style={style}
      className={classNames(s.root, className)}
      href={url}
      target={isExternalUrl ? '_blank' : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
