import classNames from 'classnames'
import React from 'react'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import {ItemContainer as Item} from './item-container'
import s from './list-layout.scss'
import {resizeContainer} from './utils'
import {ListLayoutProps} from '.'

export const ListLayout = ({events, t, narrow, mobile, listLayout}: ListLayoutProps) => {
  const style: React.CSSProperties = {
    padding: narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(s.container, s.scrollBarColor, mobile && !listLayout ? s.cardContainer : s.listContainer)

  return (
    <Resizable resizeFunc={resizeContainer}>
      <div className={className} style={style} data-hook="ev-list-layout">
        <Title />
        <ul className={s.itemsContainer}>
          {events.map((event: ExtendedEvent, index: number) => (
            <Item key={event.id} event={event} t={t} lastItem={events.length - 1 === index} />
          ))}
        </ul>
        <LoadMoreButton t={t} />
      </div>
    </Resizable>
  )
}
